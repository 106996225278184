(function() {
    'use strict';

    /**
     * An extension for a text input that adds currency formatting.
     *
     * @method alphaCurrencyInput
     *
     * @example
     *      HTML:
     *      <input alpha-currency-input
     *          type="text"
     *          ng-model="myCurrencyField"
     *          default-currency="USD"
     *          default-currency-symbol="$"
     *          decimal-precision="2"
     *          hide-group-separator="true">
     *
     * @param {Number} ng-model Model to bind this widget to.
     * @param {String} [default-currency] Currency to use when the model has no value.
     * @param {String} [default-currency-symbol] Currency symbol to use when the model has no value.
     * @param {Number} [decimal-precision] Number of decimal places to round values to.
     * @param {Boolean} [hide-group-separator] Whether to hide the group separator when displaying values.
     */

    angular
        .module('alpha.common.directives.alphaCurrencyInput', [
            'alpha.utils.I18n',
            'alpha.utils.recordData'
        ])
        .directive('alphaCurrencyInput', alphaCurrencyInput);

    alphaCurrencyInput.$inject = [
        'I18nUtil',
        'RecordDataUtil',
        '$locale',
        'DEFAULT_CURRENCY_PRECISION',
        'DataModelDesignInterface',
        'UserPreferences',
        '$q'
    ];

    function alphaCurrencyInput(
        I18nUtil,
        RecordDataUtil,
        $locale,
        DEFAULT_CURRENCY_PRECISION,
        DataModelDesignInterface,
        UserPreferences,
        $q
    ) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: link
        };
        function link(scope, element, attrs, ngModelCtrl) {
            var _showGroupSeparator = true,
                _modelCurrency, _modelSymbol, _decimalPrecision;

            scope.validateInput = _validateInput;
            scope.validatePaste = _validatePaste;

            ngModelCtrl.$formatters.push(_formatter);
            ngModelCtrl.$parsers.unshift(_parser);
            ngModelCtrl.$render = _render;

            // The model value is an object, so it needs to be deep watched
            scope.$watch(function() {
                return ngModelCtrl.$modelValue;
            }, _getModelValue, true);

            scope.$watch(I18nUtil.getLocale, _updateLocale);

            attrs.$observe('decimalPrecision', function(newVal) {
                _decimalPrecision = parseInt(newVal);
            });
            attrs.$observe('hideGroupSeparator', function(newVal) {
                _showGroupSeparator = newVal !== 'true';
            });

            element.on('keypress', _validateInput);
            element.on('paste', _validatePaste);

            function fetchClientData() {
                var deferred = $q.defer();
                UserPreferences.getClientId()
                    .then(function(clientId){
                        DataModelDesignInterface.getClient(
                            clientId,
                            function success(response) {
                                deferred.resolve(response.client);
                            }, function error(reason) {
                                deferred.reject(reason);
                            });
                    });
                return deferred.promise;
            }

            fetchClientData().then(function(data) {
                scope.enableCurrencySymbol = data.enableCurrencySymbol;
                _getModelValue();
            }).catch(function(error) {
                console.error('Error fetching client data:', error);
            });

            function _formatter(modelValue) {
                var viewValue;
                if (_.isObject(modelValue) && _.isNumber(modelValue.amount)) {
                    _modelCurrency = modelValue.baseCurrency || attrs.defaultCurrency;
                    _modelSymbol = scope.enableCurrencySymbol ? modelValue.baseCurrencySymbol || attrs.defaultCurrencySymbol : null;
                    viewValue = _getDisplayValue(modelValue.amount, _modelSymbol);
                } else {
                    _modelCurrency = null;
                    _modelSymbol = null;
                    viewValue = '';
                }
                return viewValue;
            }
            function _parser(viewValue) {
                var numericValue = RecordDataUtil.getNumericValueFromDisplayValue(viewValue),
                    modelValue;
                if (_.isNumber(numericValue)) {
                    modelValue = {
                        amount: math.round(numericValue, _.isFinite(_decimalPrecision) ? _decimalPrecision : DEFAULT_CURRENCY_PRECISION),
                        baseCurrency: _modelCurrency || attrs.defaultCurrency,
                        baseCurrencySymbol: scope.enableCurrencySymbol ? _modelSymbol || attrs.defaultCurrencySymbol : null
                    };
                    ngModelCtrl.$setViewValue(_getDisplayValue(modelValue.amount, modelValue.baseCurrencySymbol));
                } else {
                    modelValue = null;
                    ngModelCtrl.$setViewValue('');
                }
                ngModelCtrl.$render();
                return modelValue;
            }
            function _render() {
                element.val(ngModelCtrl.$viewValue);
            }
            function _getModelValue() {
                ngModelCtrl.$modelValue = 0; // force the $formatters pipeline to run
            }
            function _updateLocale() {
                _getModelValue();
            }
            function _getValidCharacters() {
                // There are so many factors that change this that it's better to get it in real time
                var symbol = _modelSymbol || attrs.defaultCurrencySymbol || '',
                    validCharacters = I18nUtil.getDisplayCharacters('Number').concat(symbol.split(''));
                if (!_showGroupSeparator) {
                    _.pull(validCharacters, $locale.NUMBER_FORMATS.GROUP_SEP);
                }
                if (_decimalPrecision === 0) {
                    _.pull(validCharacters, $locale.NUMBER_FORMATS.DECIMAL_SEP);
                }
                return validCharacters;
            }
            function _stripInvalidCharacters(val) {
                return _.filter(_.split(val, ''), function(char) {
                    return _.includes(_getValidCharacters(), char.toLowerCase());
                }).join('');
            }
            function _getDisplayValue(amount, baseCurrencySymbol) {
                return RecordDataUtil.getDisplayValueForField({
                    amount: amount,
                    baseCurrencySymbol: scope.enableCurrencySymbol ? baseCurrencySymbol : null
                }, {
                    dataType: 'Currency',
                    decimalPrecision: _.isFinite(_decimalPrecision) ? _decimalPrecision : null,
                    decimalPrecisionEnabled: _.isFinite(_decimalPrecision),
                    showGroupSeparator: _showGroupSeparator
                });
            }
            function _validateInput(event){
                if((['nl', 'nl-be'].includes($locale.id) || I18nUtil.isCommaDecimalSeparater()) && event.keyCode === 46 && _decimalPrecision !==0){
                    if(document.selection) {
                        var range = document.selection.createRange();
                        range.text = ',';
                    }else if(this.selectionStart || this.selectionStart == '0') {
                        var start = this.selectionStart;
                        var end = this.selectionEnd;
                        event.target.value = event.target.value.substring(0, start) + ',' + event.target.value.substring(end, event.target.value.length);
                        this.selectionStart = start + 1;
                        this.selectionEnd = start +1;
                    } else {
                        event.target.value += ',';
                    }
                    event.preventDefault();
                }
                if (event.key !== 'Enter' && !_.includes(_getValidCharacters(), event.key.toLowerCase())) {
                    event.preventDefault();
                }
            }
            function _validatePaste(event){
                var pastedText;
                if(_.isObject(event.originalEvent.clipboardData)){
                    pastedText = event.originalEvent.clipboardData.getData('text/plain');
                    element.val(_stripInvalidCharacters(pastedText));
                    event.preventDefault();
                }
            }
        }
    }
})();
