(function () {
  "use strict";

  /**
   * Helps manage the headings and cells in a Smart Table grid.
   * Can be used as an element when scrolling is not needed.
   *
   * @method alphaSmartTableDetailedView
   *
   * @example
   *      HTML:
   *      <alpha-smart-table-detailed-view
   *          columns='someGridColumns'></alpha-smart-table-detailed-view>
   *
   * @param {Object[]} columns The full column configuration for the smart table
   */

  angular
    .module("alpha.common.alphaSmartTable")
    .directive("alphaSmartTableDetailedView", alphaSmartTableDetailedView);

  alphaSmartTableDetailedView.$inject = ['UserDetailsInterface','RecordDataUtil','ViewRecordService'];

  function alphaSmartTableDetailedView(UserDetailsInterface,RecordDataUtil,ViewRecordService) {
    return {
      link: link,
      restrict: "AE",
      scope: true,
      templateUrl:
        applicationContextRoot +
        "/static/custom/common/alphaSmartTable/partials/alphaSmartTableDetailedView.directive.html",
    };
    function link(scope, element, attrs) {
      // Scope properties
      scope.containerId = attrs.id;
      scope.itemsPerPage = 10;
      scope.itemsPerPageOptions = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 25, 50, 75, 100,
      ];
      scope.gridColumns = _getGridColumns();
      scope.detailedViewColums = _getDetailedViewColums();
      // Scope functions
      scope.getResultRange = _getResultRange;
      scope.updatePageNumber = _updatePageNumber;
      scope.multipleLines = _multipleLines;
      scope.formatUpdateInfo = _formatUpdateInfo;
      scope.isAttachmentPresent = isAttachmentPresent;
      scope.showDigitalLink = showDigitalLink;

      var idDigitalMap = {};

      scope.$watch(
        function () {
          return _.isObject(scope.aflCtrl.grid.state)
            ? scope.aflCtrl.grid.state.currentPageSize
            : undefined;
        },
        function () {
          scope.itemsPerPage = scope.aflCtrl.grid.state.currentPageSize;
        }
      );

      // Private functions
      function _getResultRange(state) {
        if (state.numberOfResults > 0) {
          return {
            from: (state.currentPageNumber - 1) * state.currentPageSize + 1,
            to: Math.min(
              state.currentPageNumber * state.currentPageSize,
              state.numberOfResults
            ),
            total: state.numberOfResults,
          };
        } else {
          return { from: 0, to: 0, total: 0 };
        }
      }

      function _updatePageNumber(pageNumber, totalPages) {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
          scope.aflCtrl.grid.setPageNumber(pageNumber);
        }
      }

      function _getDetailedViewColums() {
        var columns = [
          scope.gridColumns
            .filter(function (column) {
              return column._fieldType.id === "_updatedByUser";
            })
            .concat(
              scope.gridColumns.filter(function (column) {
                return column._fieldType.id === "_updatedDate";
              })
            ),
          scope.gridColumns
            .filter(function (column) {
              return (
                column._fieldType.dataType !== "Attachment" &&
                !(
                  column._fieldType.dataType === "String" &&
                  (column._fieldType.widgetType === "DocumentLink" ||
                    column._fieldType.widgetType === "DigitalLink")
                ) &&
                column._fieldType.id !== "_updatedByUser" &&
                column._fieldType.id !== "_updatedDate"
              );
            })
            .slice(0, 2)
            .concat(
                scope.gridColumns.filter(function (column) {
                  return (
                      column._fieldType.dataType === "Attachment" ||
                      (column._fieldType.dataType === "String" &&
                          column._fieldType.widgetType === "DocumentLink")
                  );
                }).slice(0, 1)
            )

            .concat(
              scope.gridColumns.filter(function (column) {
                return (
                  (column._fieldType.dataType === "String" &&
                      column._fieldType.widgetType === "DigitalLink")
                );
              })
            ),
        ];
        return columns;
      }

      function showDigitalLink(row, column, indx) {
        //Reset on start of iteration
        if(indx === 0) {
          idDigitalMap = {};
        }
        var isDigitalLink  = (column.template !== 'Detail' && column.template !== 'expandCollapse'
            && (column._fieldType.dataType === 'String' && column._fieldType.widgetType === 'DigitalLink'));
        if(!isDigitalLink) {
          return isDigitalLink;
        }
        var visible = false;
        if(!idDigitalMap[row._id]) {
          var data = column.getter ? column.getter(row) : row[column.name];
          if(data) {
            visible = true;
            idDigitalMap[row._id] = true;
          }
        }
        return visible;
      }

      function _multipleLines(text,column,row) {
        var microFieldMetaData = ViewRecordService.getPopoverFieldMetaData();
        var isRedacted = _.get(microFieldMetaData[row._entityHierarchy_[0]], [column.name, 'pii_mask']) === null;
        if(isRedacted === false && isParsableToNumber(text)){
            return text;
        }
        if(text && isRedacted){
            if(column._fieldType && column._fieldType.dataType === 'String'){
                text = RecordDataUtil.getDisplayValueForField(text,column._fieldType,isRedacted);
            } else {
                text = '******';
            }
        }
        return text
          ? text.length > 72
            ? text.substring(0, 72) + "..."
            : text
          : text;
      }

      function isParsableToNumber(input) {
          return !isNaN(input) && !isNaN(parseFloat(input));
      }

      function _formatUpdateInfo(index, text, row) {
        if (index) return text ? text.split(" ")[0] : "";
        else {
          var firstName = _.get(row, "_updatedByFirstName", ""),
            lastName = _.get(row, "_updatedByLastName", ""),
            fullName = _.isEmpty(firstName)
              ? lastName
              : _.isEmpty(lastName)
              ? firstName
              : firstName + " " + lastName;
          fullName = fullName ? fullName : _.get(row, "_updatedByUser", "");
          return fullName
            ? fullName.length > 15
              ? fullName + "<br>"
              : fullName + "&nbsp;"
            : "";
        }
      }

      function _getGridColumns() {
        return (
          scope.aflCtrl.isMultiLine
            ? scope.aflCtrl.grid.detailHeaderColumns.concat(
                scope.aflCtrl.grid.multiLineColumns
              )
            : scope.aflCtrl.grid.detailHeaderColumns
        )
          .filter(function (column) {
            return (
              _.has(column, "_fieldType") &&
              _.has(column, "visible") &&
              _.has(column, "columnNumber") &&
              column.visible
            );
          })
          .sort(function (a, b) {
            return parseFloat(a.ordinal) - parseFloat(b.ordinal);
          });
      }

      function isAttachmentPresent(fieldList , row ,column){
        var col;
        _.forEach(fieldList, function (field) {
          if(!col && field._fieldType && ((field._fieldType.dataType === "Attachment" && row[field.name] )
                  || (field._fieldType.dataType === "String" && field._fieldType.widgetType === "DocumentLink" && row[field.name])))
          {
            col = field;
          }
        });
        return col ? col.name === column.name : false;
      }

    }
  }
})();
